import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Avatar, Icon, Tooltip } from "@mui/material";
import { useStore } from "@nanostores/react";
import FileChartIcon from "common/components/Icons/FileChartIcon";
import HelpIcon from "common/components/Icons/HelpIcon";
import InventoryIcon from "common/components/Icons/InventoryIcon";
import TruckIcon from "common/components/Icons/TruckIcon";
import {
	railExpandedStore,
	toggleRail,
} from "common/stores/NavigationRailStore";
import { user } from "common/stores/UserStore";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useMemo } from "react";
import styles from "./leftRail.module.css";
import SupplierOutreachIcon from "common/components/Icons/SupplierOutreachIcon";
export interface NavItemProps {
	label: string;
	path: string;
	icon?: React.ReactElement<typeof Icon>;
	getIsCurrentNavLocation?: (pathname: string) => boolean;
	onClick?: () => void;
}

export interface LeftRailProps {
	items?: Array<NavItemProps>;
	bottomItems?: Array<NavItemProps>;
	selected?: string;
	initialExpanded?: boolean;
	handleExpanderClick?: () => void;
	onNavigationClick?: () => void;
}

const NavItemTooltipWrapper = ({ title, children }) => {
	const $expanded = useStore(railExpandedStore);
	return $expanded ? (
		children
	) : (
		<Tooltip arrow={true} placement="right" title={title}>
			{children}
		</Tooltip>
	);
};

const NavItem = ({
	label,
	path,
	getIsCurrentNavLocation,
	onClick, // This is used to trigger side-effects of the navigation, not the navigation itself
	icon,
}: NavItemProps) => {
	const $expanded = useStore(railExpandedStore);
	const pathname = usePathname();
	const isCurrentNavLocation =
		getIsCurrentNavLocation?.(pathname) || pathname?.includes(path);
	return (
		<li role="none" className={isCurrentNavLocation ? styles.selected : ""}>
			<NavItemTooltipWrapper title={label}>
				<Link
					href={path}
					className={styles.navLink}
					role="menuitem"
					aria-label={`Route to ${label}`}
					onClick={() => onClick?.()}
				>
					<Avatar
						variant="square"
						sx={{
							color: isCurrentNavLocation ? "text.primary" : "text.secondary",
							height: 36,
							width: 36,
							"&:hover": {
								color: "text.primary",
							},
						}}
					>
						{icon}
					</Avatar>
					{$expanded && (
						<p
							className={`${styles.navLink} ${isCurrentNavLocation && "text-primary"}`}
						>
							{label}
						</p>
					)}
				</Link>
			</NavItemTooltipWrapper>
		</li>
	);
};

const TopNavItems = ({
	additionalNavItems,
	onNavigationClick,
}: {
	additionalNavItems?: NavItemProps[];
	onNavigationClick?: () => void;
}) => {
	const $user = useStore(user);
	// const isAdmin = $user.roles.includes("write:organization");
	const surveyAdminAccess = $user.roles.includes("survey:surveyor");
	// const surveyAdminAccess = $user.endorsements.includes("survey:surveyor"); // NOTE: not using endorsement because of quirk of Survey Co setup
	const apiKeyFeatureAccess = $user.feature_flags.includes("api-keys");
	const surveyAccess = Object.keys($user.traits).includes("survey:surveyee");
	const scmv2Access = $user.endorsements.includes(
		"supply_chain_insights:brand"
	);
	const esgInsightsAccess = $user.endorsements.includes("esg_insights:brand");
	const defaultProps = useMemo(
		() => ({
			onClick: onNavigationClick,
		}),
		[onNavigationClick]
	);

	return (
		<ul aria-label="Side navigation menu" role="menubar">
			{scmv2Access && (
				<NavItem
					label="Supply Chain"
					path="/supplyChainManager"
					icon={<TruckIcon />}
					{...defaultProps}
				/>
			)}
			{scmv2Access && (
				<NavItem
					label="Reports"
					path="/reports"
					icon={<FileChartIcon />}
					{...defaultProps}
				/>
			)}
			{surveyAccess && (
				<NavItem
					label="My Surveys"
					path="/survey/campaign-select"
					icon={<ListAltIcon />}
					{...defaultProps}
				/>
			)}
			{/* {surveyAdminAccess && (
				<NavItem
					label="Survey Admin"
					path="/survey/surveyProgressDashboard"
					icon={<ListAltIcon />}
					{...defaultProps}
				/>
			)} */}
			{surveyAdminAccess && (
				<NavItem
					label="Survey Dashboard"
					path="/survey/survey-dashboard"
					icon={<SupplierOutreachIcon />}
					{...defaultProps}
				/>
			)}
			{apiKeyFeatureAccess && (
				<NavItem
					label="API Key Management"
					path="/admin/api-keys"
					icon={<KeyIcon />}
					{...defaultProps}
				/>
			)}
			{esgInsightsAccess && (
				<NavItem
					label="Inventory Data"
					path="/esgInsights/ng/inventory-data"
					icon={<InventoryIcon width="21" height="21" />}
					getIsCurrentNavLocation={(pathname: string) =>
						pathname?.includes("/esgInsights/ng/inventory-data") ||
						pathname?.includes("/esgInsightsUpload")
					}
					{...defaultProps}
				/>
			)}
			{esgInsightsAccess && (
				<NavItem
					label="Emissions"
					path="/esgInsights/ng/emissions-analysis"
					icon={<BubbleChartOutlinedIcon />}
					{...defaultProps}
				/>
			)}
			{additionalNavItems?.map((item: NavItemProps) => (
				<NavItem key={item.label} {...item} {...defaultProps} />
			))}
		</ul>
	);
};

const BottomNavItems = ({
	additionalNavItems,
	onNavigationClick,
}: {
	additionalNavItems?: NavItemProps[];
	onNavigationClick?: () => void;
}) => {
	const $user = useStore(user);
	const isAdmin = $user.roles.includes("write:organization");
	const surveyAccess = Object.keys($user.traits).includes("survey:surveyee");
	const scmv2Access = $user.endorsements.includes(
		"supply_chain_insights:brand"
	);
	const defaultProps = useMemo(
		() => ({
			onClick: onNavigationClick,
		}),
		[onNavigationClick]
	);
	return (
		<ul aria-label="Side navigation lower menu" role="menubar">
			{isAdmin && (
				<NavItem
					label="User Management"
					path="/admin/users"
					icon={<PeopleOutlineIcon />}
					{...defaultProps}
				/>
			)}
			{additionalNavItems?.map((item: NavItemProps) => (
				<NavItem key={item.label} {...item} {...defaultProps} />
			))}

			{scmv2Access && (
				<NavItem
					label="Help"
					path="/facade/auth/owl-docs?r=/docs/getting-started-with-supply-chain-manager"
					icon={<HelpIcon color="var(--oc-palette-secondary-400)" />}
					{...defaultProps}
				/>
			)}
			{surveyAccess && isAdmin && (
				<NavItem
					label="Survey Management"
					path="/survey/survey-management"
					icon={<FormatListBulletedIcon />}
					{...defaultProps}
				/>
			)}
		</ul>
	);
};

const AvatarExpansionTooltipWrapper = ({ children }) => {
	const $user = useStore(user);
	const $expanded = useStore(railExpandedStore);

	return $expanded ? (
		<div className={styles.currentOrg}>{children}</div>
	) : (
		<Tooltip
			arrow={true}
			placement="right"
			title={$user.organization}
			className={styles.headerCollapsed}
		>
			{children}
		</Tooltip>
	);
};

/**
 * A side rail navigation for optera themed pages.
 */
function LeftRail({
	items = [],
	bottomItems = [],
	onNavigationClick,
}: LeftRailProps) {
	const $user = useStore(user);
	const $expanded = useStore(railExpandedStore);
	const ChevronIconUse = $expanded ? ChevronLeftIcon : ChevronRightIcon;

	return (
		<nav id="leftRail-nav" className={styles.nav} aria-label="Side navigation">
			<Tooltip
				arrow={true}
				placement="right"
				title={$expanded ? "Collapse" : "Expand"}
			>
				<button
					type="button"
					className={styles.toggle}
					onClick={toggleRail}
					aria-expanded={$expanded ? "true" : "false"}
					aria-controls="leftRail-nav"
					aria-label="Widen or narrow left side nav"
				>
					<ChevronIconUse style={{ fontSize: 20 }} />
				</button>
			</Tooltip>
			<div className={styles.marginTransition}>
				<div className={$expanded ? "" : styles.avatarMarginClosed}>
					{$user.organization && (
						<AvatarExpansionTooltipWrapper>
							<Avatar variant="rounded" className={styles.companyAvatar}>
								{$user.organization[0]}
							</Avatar>
							{$expanded && (
								<p className={`body2 ${styles.currentOrgName}`}>
									{$user.organization}
								</p>
							)}
						</AvatarExpansionTooltipWrapper>
					)}
				</div>
				<div>
					<TopNavItems
						additionalNavItems={items}
						onNavigationClick={onNavigationClick}
					/>
				</div>
				<div className={styles.footer}>
					<BottomNavItems
						additionalNavItems={bottomItems}
						onNavigationClick={onNavigationClick}
					/>
				</div>
			</div>
		</nav>
	);
}

export default LeftRail;
